.hero{
  height: calc(90vh - 74px);
  @media only screen and (max-width: $laptop){
    height: auto;
    min-height: 100vh;
  }
  &__text{
    color: $white;
    margin-top: 150px;
    &--white{
      color: $white;
    }
    &--yellow{
      color: $primary;
    }
    &__title{
      font-size: 76px;
      margin-top: 25px;
      font-weight: 900;
      @media only screen and (max-width: $mobile){
        font-size: 53px;
      }
    }
    h4{
      margin-top: 25px;
      opacity: .85;
      @media only screen and (max-width: $mobile){
        font-size: 18.66px;
      }
    }
  }
  &__badge{
    position: relative;
    min-height: 261px;
    @media only screen and (max-width: $tablet){
      position: unset;
      display: flex;
      justify-content: flex-end;
      overflow: hidden;
    }
    img{
      position: absolute;
      top: 100%;
      right: 50px;
      transform: translateY(-50%);

      @media only screen and (max-width: $tabletBig){
        top: 0;
        transform: unset;
      }
      @media only screen and (max-width: $tablet){
        position: unset;
        transform: unset;
        margin-right: -60px;
      }
    }
  }
}