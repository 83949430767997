.footer{
  color: $white;
  // padding-top: 120px;
  &__line{
    color: $white;
    opacity: .2;
    margin-top: 0;
    margin-bottom: 0;
  }
  .caption{
    margin-top: 45px;
    @media only screen and (max-width: $mobile){
      font-size: 14px;
    }
    margin-top: 35px;
  }
  &__coa{
    margin-top: 5px;
    
    @media only screen and (max-width: $mobile){
      font-size: 26px;
      font-weight: 500;
      margin-top: 16px;
    }
    &--arrow{
      cursor: pointer;
      @media only screen and (max-width: $mobile){
        h2{
          font-size: 36px;
        }
      }
      &:hover{
        color: $primary;
      }
      &:hover svg{
        fill: $primary
      }

      svg{
        margin-left: 1rem;
      }
      
      // &::after {
      //   content: url("../../images/icons/right_arrow.svg");
      //   margin-left: 16px;
      // }
    }
  }
  &__info{
    margin-top: 85px;
    margin-bottom: 45px;
    &--lower{
      margin-top: 15px;
    }
    &__logo{
      @media only screen and (max-width: $tabletBig){
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
      }
      @media only screen and (max-width: $mobile){
        
      }
    }
    &__company{
      @media only screen and (max-width: $tabletBig){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
      }
      @media only screen and (max-width: $mobile){
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        align-items: start;
        padding-left: 50px;
      }
    }
  }
  &__soc-med{
    @media only screen and (max-width: $tabletBig){
      margin-top: 30px;
    }
    &__icons{
      a{
        display: inline-block;
        img{
          width: 25px;
          height: 25px;
        }
        &+a{
          margin-left: 20px;
        }
      }
    }
  }
}