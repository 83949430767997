.navbar {
  // background: $black;
  height: 74px;
  padding: 0;
  &__logo {
    padding-top: 24px;
    a {
      display: inline-block;
      padding: 11.25px 0 11.71px;
      img {
        height: 27.04px;
        @media only screen and (max-width: $mobile){
          height: 18.84px;
        }
      }
    }
  }
  &__buttons{
    padding-top: 24px;
    
  }
  .navbar-toggler {
    &:focus {
      box-shadow: none;
    }
    .navbar-toggler-icon {
      background: url("../../images/icons/navigation/menu.svg");
    }
  }
}
