.project-info{
  color: $white;
  padding-top: 100px;
  @media only screen and (max-width: $mobile){
    padding-top: 50px;
  }

  &__title{
  }
  &__intro{
    @media only screen and (max-width: $mobile){
      margin-top: 30px;
    }
    >p{
      opacity: .85;
    }
    &__info{
      margin-top: 30px;
      @media only screen and (max-width: $mobile){
        flex-direction: column;
        margin-top: 30px;
        // margin-bottom: 30px;
      }
      p{
        font-weight: 700;
        @media only screen and (max-width: $mobile){
          
          & + p{
            margin-top: 20px;
          }
        }
        span{
          opacity: .85;
        }
      }
    }
  }
  &__cover-img{
    margin-top: 50px;
    margin-bottom: 50px;
    @media only screen and (max-width: $mobile){
      margin-bottom: 30px;
    }
  }
  &__challenge,
  &__solution{
    margin-bottom: 50px;
    
    p{
      margin-top: 20px;
      opacity: .85;
    }
  }
  &__result{
    margin-top: 50px;
    margin-bottom: 50px;
    @media only screen and (max-width: $mobile){
      margin-top: 35px;
    }
    p{
      margin-top: 20px;
      opacity: .85;
    }
  }
}