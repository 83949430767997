.offcanvas-contact{
  background: $black;
  color: $white;
  width: 628px;
  
  @media only screen and (max-width: $mobile){
    width: 100%;
  }
  h3{
    width: calc(min(400px, 100%));
  }
  &__body{
    padding-left: 50px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media only screen and (max-width: $laptop){
      display: unset;
      padding-top: 100px;
      padding-bottom: 100px;
    }
    @media only screen and (max-width: $mobile){
      justify-content: unset;
      padding: 80px 25px ;
    }
  }
  &__close-button{
    background: transparent;
    border: none;
  }
  &__input{
    width: calc(min(452px, 100%));
    background: $blacky;
    color: white;
    // border: 1px solid #212121;
    border: 1px solid #E2E7F0;
    padding: 14px 20px;
    &:focus{
      background: $blacky !important;
      color: $white;
      border-color: #A0AEC0;
      box-shadow: none;
    
    }
    &::placeholder{
      color: $white;
    }
    &-file{
      background: $blacky;
      color: $white;
      // &::-webkit-file-upload-button{
      //   background: $blacky;
      //   border: 2px solid $primary;
      //   font-weight: 400;
      //   color: $white;
      // }
      // &:hover ::-webkit-file-upload-button,
      // &:focus ::-webkit-file-upload-button,
      // &:focus-within &::-webkit-file-upload-button{
      //   background-color: $primary !important;
      // }
    }
  }
  &__button{
    width: calc(min(452px, 100%));
  }
  .privacy-policy-link{
    color: $primary;
  }
  .error{
    border: 1px solid $primary;
    position: relative;
    &__icon{
      width: 20px;
      height: 20px;
      background-color: green;
      position: absolute;
      top: 50%;
      left: calc(min(412px, 90%));
      transform: translateY(-50%);
      background: url('../../images/icons/warning.svg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .confirmation-message{
    width: calc(min(452px, 100%));
    background: #ECF9EE;
    color: #3DB54E;
    border: 1px solid #3DB54E;
    padding: 14px 20px;
    font-size: 1rem;
    font-weight: 500;
  }
}
.modal-backdrop{
  background: rgba(0, 0, 0, .44);
  // filter: blur(29px);
  backdrop-filter: blur(29px);
}
.modal-backdrop.fade{
  opacity: 0;
  // backdrop-filter: blur(29px);
}
.modal-backdrop.show{
  opacity: 1;
  // backdrop-filter: blur(29px);
}


.custom-checkbox .form-check-input:checked~.form-check-label::before{
  background-color:black;
}
.form-check-input{
  background-color: $black;
  width: 28px;
  height: 28px;
  border-color: #E2E7F0;
}
.form-check-input:focus{
  outline: none;
  border-color: #A0AEC0;
  box-shadow: none;
}
.form-check-input:checked{
  background-color: $primary !important;
  outline: none;
}
