.page-not-found{
  min-height: 100vh;
  background: url('../../images/hero_background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
  
  .navbar{
    position: fixed;
    top: 0%;
    width: 100%;
  }

  &__message{
    color: $white;
    text-align: center;
    h1{
      font-size: 76px;
      margin-bottom: 25px;
      span{
        color: $primary;
      }
    }
    h4{
      margin-bottom: 25px;
    }
  }

}