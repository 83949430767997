@import "./theme";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  // padding: 14px 24px;
  height: 50px;
  padding: 0 24px;
  &--primary {
    background: $primary;
    color: $black;
    transition: all 0.3s ease;
    &:hover {
      background: $white;
    }
  }
  &--secondary {
    background: $white;
    color: $black;
  }

  // &:focus{
  //   outline: none;
  //   border: none;
  //   box-shadow: none ;
  // }
  // &:active{
  //   outline: none;
  //   border: none;
  //   box-shadow: none;
  // }
}

// SLIDER ARROWS
// .slick-prev,
// .slick-next {
//   top: 0;
//   transform: unset;
//   color: unset;
//   width: 25px;
//   height: 20px;
// }
// .slick-next {
//   content: "";
//   background: url("../images/icons/right_arrow.svg") no-repeat;
//   background-size: cover;
//   right: 0;
//   &::before {
//     content: "";
//     width: 100%;
//   }
// }
// .slick-prev {
//   content: "";
//   background: url("../images/icons/left_arrow.svg") no-repeat;
//   background-size: cover;
//   right: 25px;
//   left: unset;
//   z-index: 22;
//   &::before {
//     content: "";
//     width: 100%;
//   }
// }

.prev-arrow,
.next-arrow {
  display: block;
  width: 14px;
  height: 16px;
  position: absolute;
  top: -67px;
  right: 0;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 14px 16px;
  @media only screen and (max-width: $tablet){
    top: unset;
    bottom: -45px;
  }
}
.prev-arrow {
  // z-index: 22;
  background-image: url("../images/icons/left_arrow.svg");
  margin-right: 45px;
  @media only screen and (max-width: $tablet){
    left: 50%;
    transform: translateX(-25px);
  }
}
.next-arrow {
  background-image: url("../images/icons/right_arrow.svg");
  @media only screen and (max-width: $tablet){
    right: 50%;
    transform: translateX(25px);
  }
}
// .slick-slide{
//   padding-left: 30px;
// }
// .slick-list [data-index="0"]{
//   padding-left: 0;
// }

