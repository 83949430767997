$primary: #FFD500;
$blue: #0080FF;
$green: #3DB54E;
$orange: #F26722;
$blacky: #0A0A0A;

$white: #FFF;
$gray-100: #F7FAFC;
$gray-200: #EDF2F7;
$gray-300: #E2E7F0;
$gray-400: #CCD6E0;
$gray-500: #A0AEC0;
$gray-600: #A0AEC0;
$gray-700: #4A5568;
$gray-800: #2C3748;
$gray-900: #1A202C;
$black: #0D0D0D;

// breakpoints
$laptop: 1366px;
$tabletBig: 974px;
$tablet: 767px;
$mobile: 576px;