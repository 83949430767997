.about-us {
  // background: $black;
  background: linear-gradient(180deg, rgba(13,13,13,1) 0%, rgba(10,10,10,1) 100%);
  // height: 100vh;
  .caption{
    margin-bottom: 15px;
    @media only screen and (max-width: $mobile){
      font-size: 14px;
    }
  }
  &__text {
    color: $white;
    p{
      opacity: .85;
    }
    
    h2{
      margin-bottom: 35px;
    }
  }

  &__tabs {
    @media only screen and (max-width: $tabletBig){
      margin-top: 36px;
      margin-bottom: 36px;
    }
    &__tab.nav-link {
      color: $white;
      text-align: left;
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 0;
      padding: 12px 0 12px 36px;
      &.active {
        background: none;
        color: $primary;
        border-color: $primary;
      }
    }
  }
  &__tabs-content{
    color: $white;
    p,small{
      opacity: .85;;
    }
    h3{
      margin-bottom: 28px;
    }
    p{
      margin-bottom: 20px;
    }
    .technologies-item{
      img{
        width: 25px;
        margin-right: 6px;
        filter: grayscale(100%);
        transition: all .3s ease-in-out;
      }
      &:hover{
        img{
          filter: grayscale(0%);
        }
      }
    }
  }

  &__coa {
    position: relative;
    margin-top: 60px;
    @media only screen and (max-width: $tablet){
      background: $primary;
    }
    &__img{
      width: 100%;
      object-fit: cover;
    }
    &__text {
      position: absolute;
      bottom: 0;
      right: 11.25px;
      background: $blacky;
      color: $white;
      padding: 24px 47px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      @media only screen and (max-width: $tablet){
        position: unset;
        padding: 0;
        background: $primary;
        color: $black;
        padding-top: 30px;
        padding-bottom: 30px;
      }
      & > :first-child {
        @media only screen and (max-width: $tablet){
          font-size: 26px;
          font-weight: 500;
        }
      }
      &:hover {
        background: $primary;
        color: $black;
      }
      &:hover svg{
        fill: $black;
      }
      &--arrow svg{
        margin-left: 1rem;
        transition: all .3s ease-in-out;
      }
      // &:hover &--arrow::after{
      //   content: url("../../images/icons/right_arrow_black.svg");
      // }
      // &--arrow::after {
      //   content: url("../../images/icons/right_arrow.svg");
      //   margin-left: 16px;
      //   @media only screen and (max-width: $tablet){
      //     content: url("../../images/icons/right_arrow_black.svg");
      //   }
      // }
    }
  }
}
