.offcanvas.offcanvas-menu{
  width: 100vw;
  // background: $black;
  @media only screen and (max-width: $tablet){
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .offcanvas__wrapper{
    position: relative;
    background: $black;
    height: 100%;
    
    
    &::before{
      // content: '';
      // width: 100%;
      // height: 100%;
      // position: absolute;
      // background: $black;
      // left: -100%;
      // top: 0;
    }
    &::after{
      // content: '';
      // width: 100%;
      // height: 100%;
      // position: absolute;
      // background: $primary;
      // right: -100%;
      // top: 0;
    }
    .offcanvas__header{
      &__logo{
        padding-top: 24px;
        position: relative;
        @media only screen and (max-width: $tablet){
          background: $primary;
        }
        &::before{
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 99%;
          background: $black;
          @media only screen and (max-width: $tablet){
            background: $primary;
          }
        }
        a{
          display: inline-block;
          padding: 11.25px 0;
          img{
            height: 27px;
            @media only screen and (max-width: $mobile){
              height: 18.84px;
            }
          }
        }
      }
      &__buttons{
        padding-top: 24px;
        background: $primary;
        position: relative;
        &::after{
          content: '';
          width: 120%;
          height: 100%;
          position: absolute;
          top: 0;
          right: -120%;
          background: $primary
        }
        button{
          &:focus{
            outline: none;
            box-shadow: none;
          }
        }
        .button__close{
          background: none;
          border: none;
        }
      }
    }
    .offcanvas__body{
      &__contact{
        background: $black;
        color: $white;
        padding-top: 150px;
        position: relative;
        @media only screen and (max-width: $tablet){
          padding-top: 100px;
          padding-bottom: 50px;
        }
        @media only screen and (max-width: $mobile){
          padding-top: 50px;
        }
        &::before{
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 99%;
          background: $black;
        }
        &::after{
          @media only screen and (max-width: $tablet){
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: -100%;
            background: $black;
          }
        }
        p{
          margin: 0;
          @media only screen and (max-width: $mobile){
            font-size: 14px;
          }
        }
        h1{
          margin-top: 5px;
          @media only screen and (max-width: $tablet){
            font-size: 36px;
          }
        }
        h3{
          margin: 30px 0 0;
          @media only screen and (max-width: $tablet){
            font-size: 18.66px;
            margin-top: 42px;
          }
          @media only screen and (max-width: $mobile){
            & + h3{
              margin-top: 17px;
            }
          }
        }
      }
      &__nav{
        background: $primary;
        padding-top: 150px;
        position: relative;
        @media only screen and (max-width: $tablet){
          padding-top: 100px;
          padding-bottom: 50px;
        }
        @media only screen and (max-width: $mobile){
          padding-top: 50px;
        }
        &::after{
          content: '';
          width: 120%;
          height: 100%;
          position: absolute;
          top: 0;
          right: -120%;
          background: $primary;
        }
        &::before{
          @media only screen and (max-width: $tablet){
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: -100%;
            background: $primary;
          }
        }
        ul{
          margin-left: 100px;
          @media only screen and (max-width: 1024px){
            margin-left: 0;
          }
        }
        .nav-link{
          cursor: pointer;
        }
        li{
          @media only screen and (max-width: $tablet){
            font-size: 36px;
          }
        }
        span{
          display: inline-block;
          color: $black;
          position: relative;
          z-index: 1;
          &::after{
            content: '';
            width: 110%;
            height: 30px;
            position: absolute;
            left: -5%;
            bottom: 5px;
            background: $white;
            opacity: 0;
            z-index: -1;
            transition: all .3s ease;
          }
          &:hover,
          &:active,
          &:focus{
            &::after{
              opacity: .3;
            }
          }
          a{
            color: $black;
          }
        }
      }
    }
  }
}
