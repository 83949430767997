.team {
  $slides-space: 30px;
  margin-top: 120px;
  margin-bottom: 120px;
  &__about-section {
    h2 {
      color: $white;
      margin-top: 5px;
    }
    p {
      color: $white;
      text-align: right;
      align-self: center;
      @media only screen and (max-width: 991px) {
        text-align: left;
        margin-top: 16px;
        margin-left: 0;
      }
    }
  }

  &__slider {
    margin-top: 90px;
    .slick-slide {
      padding-left: $slides-space;
      height: auto;
      & > div {
        height: 100%;
      }
    }
    .slick-list {
      margin-left: -$slides-space;
      .slick-track {
        display: flex;
      }
    }
    .slick-list [data-index='0'] {
      // padding-left: 0;
    }
    &__box {
      position: relative;
      overflow: hidden;
      opacity: 0.9;
      // min-height: 450px;
      height: 100%;
      &__image {
        opacity: 0.8;
        transition: all 0.3s ease-in-out;
        height: 100%;
        object-fit: cover;
      }
      &__text {
        color: $white;
        position: absolute;
        bottom: -100%;
        left: 5%;
        width: 90%;
        background: $black;
        opacity: 0.83;
        padding: 16px;
        transition: all 0.3s ease-in-out;
        &--position {
          color: $primary;
          margin-bottom: 6px;
        }
      }
      &__soc-med {
        margin-top: 8px;
        &--linkedin {
          opacity: 0.2;
          margin-right: 13px;
        }
      }
      &:hover &__image {
        opacity: 1;
      }
      &:hover &__text {
        bottom: 10px;
      }
    }
  }
}
