h1, .h1{
  font-size: 3.157em;
  font-weight: 700;
}
h2, .h2{
  font-size: 2.369em;
  font-weight: 700;
}
h3, .h3{
  font-size: 1.777em;
  font-weight: 500;
}
h4, .h4{
  font-size: 1.333em;
  font-weight: 500;
}
button{
  font-size: 1em;
  font-weight: 500;
}
caption, .caption{
  font-size: 1em;
  font-weight: 500;
  letter-spacing: .2em;
  text-transform: uppercase;
}
p{
  font-size: 1em;
}
small{
  font-size: .75em;
}