@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "./assets/sass/shared";
@import "./assets/sass/typography";
@import "./assets/sass/theme";
@import "./assets/sass/reset";
@import "./assets/sass/layouts/navbar";
@import "./assets/sass/layouts/navbarMenu";
@import "./assets/sass/layouts/aboutUs";
@import "./assets/sass/layouts/hero";
@import "./assets/sass/layouts/portfolio";
@import "./assets/sass/layouts/testimonials";
@import "./assets/sass/layouts/team";
@import "./assets/sass/layouts/footer";
@import "./assets/sass/layouts/pageNotFound";
@import "./assets/sass/layouts/contact";
@import "./assets/sass/layouts/portfolioViewPage";

@font-face {
  font-family: "HelveticaNowDisplay";
  src: local("HelveticaNowDisplay"),
    url("./fonts/HelveticaNowDisplay/HelveticaNowDisplay-Bold.otf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "HelveticaNowDisplay";
  src: local("HelveticaNowDisplay"),
    url("./fonts/HelveticaNowDisplay/HelveticaNowDisplay-Regular.otf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "HelveticaNowDisplay";
  src: local("HelveticaNowDisplay"),
    url("./fonts/HelveticaNowDisplay/HelveticaNowDisplay-Medium.otf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "HelveticaNowDisplay";
  src: local("HelveticaNowDisplay"),
    url("./fonts/HelveticaNowDisplay/HelveticaNowDisplay-Black.otf") format("truetype");
  font-weight: 900;
}

body {
  background: $blacky;
  font-family: HelveticaNowDisplay;
}

.header {
  background: url("./assets/images/hero_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  min-height: 90vh;
}
.caption {
  color: $primary;
}

.about-us,
.portfolio,
.testimonials,
.team{
  min-height: 50vh;
}