.testimonials{
  $slides-space: 30px;
  // background: $black;
  padding-top: 120px;
  &__about-section{
    h2{
      color: $white;
      margin-top: 5px;
    }
    p{
      color: $white;
      text-align: right;
      align-self: center;
      @media only screen and (max-width: 991px){
        text-align: left;
        margin-top: 16px;
        margin-left: 0;
      }
    }
  }
  .slick-slide{
    padding-left: $slides-space;
  }
  // .slick-list [data-index="0"] {
  //   padding-left: 0;
  // }
  .slick-list{
    margin-left: -$slides-space;
  }

  &__quote{
    color: $white;
    background: $black;
    // margin-top: 60px;
    padding: 68px 48px 51px;
    min-height: 426px;

    display: flex !important;
    flex-direction: column;
    >img{
      margin-bottom: 32px;
      display: block;
      width: 42px;
    }
    &__text{
      opacity: .87;
      margin-bottom: 42px;
      display: block;
    }
    &__author-image{
      margin-right: 16px;
      width: 48px;
      height: 48px;
      img{
        width: 100%;
        border-radius: 50%;
      }
    }
    &__author-position{
      opacity: .5;
      margin-top: 5px;
    }
  }
  #testimonials-slider{
    margin-top: 90px;
  }
}